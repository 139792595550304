@import '~antd/dist/antd.less';

// Ant sizes
// 	xs: '480px',
// 	sm: '576px',
// 	md: '768px',
// 	lg: '992px',
// 	xl: '1200px',
// 	xxl: '1600px',

// media queries - mobile first: use min-width
@desktop-up: ~'only screen and (min-width: 992px)';
@phone-down: ~'only screen and (max-width: 576px)';
@phone-landscape: ~'only screen and (max-width: 826px) and (orientation: landscape)';
@tablet-up: ~'only screen and (min-width: 576px)';
@tablet-down: ~'only screen and (max-width: 575px)';
@tablet-landscape: ~'only screen and (min-width: 576px) and (orientation: landscape)';
@tablet-down-landscape: ~'only screen and (max-width: 740px) and (orientation: landscape)';
@landscape-down: ~'only screen and (orientation: landscape)';
@landscape: ~'only screen and (min-width: 576px)';

// Reset default styles
html,
body,
p,
ul,
li {
	margin: 0;
	padding: 0;
	text-decoration: none;
	font-family: 'Philosopher', 'Segoe UI';
}

blockquote {
	font-family: 'Philosopher', 'Segoe UI';
}

a {
	margin: 0;
	padding: 0;
	font-family: 'Philosopher', 'Segoe UI';
	text-decoration: underline dotted;
	-webkit-text-decoration-color: lightGrey;
	/* Safari */
	text-decoration-color: lightGrey;
}

body {
	overflow: auto !important;
}

.ant-scrolling-effect {
	overflow-y: scroll !important;
	width: 100% !important;
}

// colour theme
@slate: rgba(33, 55, 63, 1);
@silver: rgb(192, 192, 192);
@lightGrey: #d9d9d9;
@yellowed: rgba(242, 185, 79, 1);
@pastelGold: #d2d289;
@gold: rgba(190, 173, 42, 1);
@shadowGold: rgba(125, 115, 30, 1);
@bronzeGold: rgba(166, 108, 38, 1);
@lightBrown: rgba(114, 67, 25, 1);
@darkBrown: rgba(63, 32, 14, 1);
@crimson: rgba(123, 0, 0, 1);

// logo palette
@darkGold: #aa8800ff;
@mediumGold: #d4aa00;
@mainGold: #c7ae40;
@mainGoldOld: #bb981d;
@lightGold: #ffcc00ff;
@darkCrimson: #2b0000ff;
@mediumCrimson: #550000ff;
@lightCrimson: #800000ff;
@darkRed: #aa0000ff;
@mediumRed: #d40000ff;
@lightRed: #ff0000ff;

html,
body {
	min-height: 100vh;
	color: black;

	#root {
		min-height: 100vh;
	}
}

h1 {
	font-size: 2em;
	color: black;
}

h2 {
	font-size: 1.5em;
	color: black;
}

.ant-modal {
	max-width: 850px;
}

.ant-alert {
	margin-top: 1rem;
	.ant-alert-message {
		color: unset;
		font-weight: bolder;
	}
}

.ant-btn {
	border-color: rgba(0, 0, 0, 0);
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	&.ant-btn-dashed {
		border-color: lightGray;
	}
}

.shadow {
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
}
.noShadow {
	box-shadow: none;
}
.disabled {
	opacity: 0.5;
	// cursor: not-allowed;
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	background-color: #f5f5f5;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
	background-color: #f0f2f5;
}

::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(33, 55, 63, 0.1);
}

.ant-modal-centered {
	white-space: nowrap; // prevent wrapping modal after :before element, that has screen height

	> .ant-modal {
		white-space: normal; // restore wrapping for internal elements
	}
}

.ant-typography {
	margin-bottom: 0 !important;
}

.ant-modal-title {
	font-size: 20px;
}

.modalSubtitle {
	padding: 0.5rem 0 0 0;
	font-size: medium;
}

// Campaign Select Drawer
.ant-drawer-body {
	padding: 1rem;
}

// Profile modal avatar upload
.ant-modal-footer {
	// round buttons instead or rect
	button {
		height: 32px;
		padding: 4px 16px;
		font-size: 14px;
		border-radius: 32px;
	}
}
.ant-upload,
.ant-upload-span {
	cursor: pointer;
	transition: all 0.3s;
}

#profileForm {
	.ant-upload-list-item-info {
		transform: translateY(-5px);
	}
	.avatar {
		margin: 1rem;
		transform: scale(1.5);
		font-size: large;
	}
}

.ql-editor {
	user-select: auto;
	word-break: break-word;
}

.upgradeModal {
	.ant-result {
		padding: 0;
		.ant-result-extra {
			margin: 0;
		}
		.ant-result-icon {
			transform: scale(0.7);
			margin: 0;
		}
	}
	.ant-modal-footer {
		display: none !important;
	}
	.upgrade {
		margin: 1rem 0 1.5rem 0;
		transform: scale(1.2);
	}

	#pricing {
		padding: 0 0 1rem 100px;
		@media @phone-down {
			padding: 0 0 1rem 0;
		}
	}

	.tierCard {
		@media @phone-down {
			* {
				padding: 0;
			}
			.ant-card-head {
				padding: 1rem 0.5rem;
			}
			.ant-card-body {
				padding: 0.5rem;
			}
		}
	}

	.premium:hover {
		cursor: pointer;
	}
}

@primary-color: #c7ae40;@heading-color: #c7ae40;@secondary-color: rgb(192, 192, 192);@text-color-secondary: rgb(192, 192, 192);